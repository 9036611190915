import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/kooperationspartner.css"
import { hafen2Content } from "../../content/kooperationen/hafen2"
import { SettingsContext } from "../../contexts/settings"

const Hafen2Page = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = hafen2Content[settings.language]
    return (
        <Template currentPage="cooperations">
            <section>
            <div>
                <h1>
                    <Link to="/kooperationen" class="back-arrow">{content.arrow}{content.headline}</Link> 
                </h1>
                <h2>
                    {content.secondHeadline}
                </h2>
            
            <div className="pic-text-hafen">
                <div className="pic-hafen-container">
                    <img className="pic-hafen" src={settings.isHighContrast ? "/logos/Kooperationspartner/bw/inverted/bw_inverted_Logo_Hafen2.png" : "/logos/Kooperationspartner/bw/bw_Logo_Hafen2.png"} alt="Logo vom Hafen 2"/>
                </div>
                <div className="text-hafen">
                    <p>
                        {content.first}
                    </p>
                    <p>
                        {content.second}
                    </p>
                    <p>
                        {content.third}
                    </p>
                    <p className="end-of-site">
                        {content.fourth}
                        <a href="https://www.hafen2.net/" target="_blank" rel="noopener noreferrer" className="link">{content.link}</a>
                        {content.fifth}
                    </p>
                    </div>
            </div>

            </div>    
            </section>
        </Template >
    )
}

export default Hafen2Page