const hafen2Content = {
    german: {
        arrow: "<< ",
        headline: "KOOPERATIONEN",
        secondHeadline: "Hafen 2",
        first: "Hafen 2 ist eine vielfältige Mischung aus Kunst-Ort, Streichelzoo, Konzertstätte, Café, Programmkino, Erholungswiese und House-Klub. Manchmal ist der Hafen 2 auch mehr als das und zeigt Theater, Diskussionen sowie Lesungen.",
        second: "Getragen wird das Kulturzentrum vom gemeinnützigen Verein \"suesswasser e.V - Kunst und Kultur im Hafen Offenbach\".",
        third: "Hafen 2 fördert progressive Kunst, Kultur und Selbstverwirklichung. Akteur:innen und Gäste sollen hier Inspirationen mitnehmen. Sie sollen außerdem soziale Fähigkeiten und Kritikfähigkeit herausbilden, Geschmacksbildung trainieren, zu Engagement animiert und zu alternativen Lebensentwürfen ermutigt werden.",
        fourth: "Hier geht's zur Website vom ",
        link: "Hafen 2",
        fifth: "."
    },
    english: {
        arrow: "<< ",
        headline: "COOPERATIONS >>",
        secondHeadline: "Hafen 2",
        first: "Hafen 2 (meaning \"harbor 2\") is an exciting combination of artistic space, petting zoo, concert hall, café, arthouse cinema, meadow, art studio and house club. Sometimes it is even more than that, showing theater, fashion, debates as well as readings.",
        second: "Hafen 2 is run by the non-profit association \"suesswasser e.V - Kunst und Kultur im Hafen Offenbach\" (\"Art and Culture in Offenbach’s harbor\").",
        third: "Hafen 2 supports progressive art and culture. With this, we would like to further social abilities and the ability to be critical. Furthermore, Hafen 2 would like to enable self-realization, to help developing people's taste as well as to encourage everyone's involvement in society and in alternative life concepts.",
        fourth: "Click here for the website of ",
        link: "Hafen 2",
        fifth: "."
    }
}
export {hafen2Content}